document.addEventListener("DOMContentLoaded", function () {
	var blockVideos = document.querySelectorAll(".block-video");
  
	if (blockVideos.length > 0) {
		blockVideos.forEach(function (el) {
			var preview = el.querySelector(".video-preview");
			var play = el.querySelector(".play");
			var iframe = el.querySelector("iframe");

			play.addEventListener("click", function () {
				preview.style.display = "none";
				play.style.display = "none";
				iframe.src += "?autoplay=1";
			});
		});
	}
	
  });
  