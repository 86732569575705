$(() => {
	if ($(".term-filter-wrapper").length > 0) {

		$(".term-filter-wrapper").each((i, el) => {
			let filtersList = $(el).find(".filters-list");

			const navPrev =
				'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none"><g clip-path="url(#clip0_4492_3237)"><path d="M23.115 11.115L21 9L12 18L21 27L23.115 24.885L16.245 18L23.115 11.115Z" fill="none"/></g><defs><clipPath id="clip0_4492_3237"><rect width="36" height="36" fill="none"/></clipPath></defs></svg>';
			const navNext =
				'<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none"><g clip-path="url(#clip0_4492_3238)"><path d="M14.9998 9L12.8848 11.115L19.7548 18L12.8848 24.885L14.9998 27L23.9998 18L14.9998 9Z" fill="none"/></g><defs><clipPath id="clip0_4492_3238"><rect width="36" height="36" fill="none"/></clipPath></defs></svg>';

			filtersList.owlCarousel({
				loop: false,
				nav: true,
				dots: false,
				navText: [navPrev, navNext],
				navContainer: ".carousel-nav",
				autoWidth: true,
                margin: 16
			});
		});

	}
});
