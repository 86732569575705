"use strict";

document.addEventListener("DOMContentLoaded", function () {

    anchorsChromeBugResolver();
	
    // START -- Add attributes to outer links
    const links = Array.from(document.querySelectorAll('a[href^="http://"], a[href^="https://"]'));
    const currentHostname = window.location.hostname;

    links.forEach(function (link) {
        if (!link.href.includes(currentHostname)) {
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "nofollow");
        }

        if (link.href.endsWith(".pdf") || link.href.endsWith(".html")) {
            link.setAttribute("target", "_blank");
        }
    });
    // END -- Add attributes to outer links

    // START -- Back to top management
    const backToTopButton = document.querySelector(".back-to-top");
    if (backToTopButton) {
        backToTopButton.addEventListener("click", function () {
            window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
    // END -- Back to top management

    // START - Scroll to anchor
    function gotoAnchor(anchor) {
        const targetElement = document.querySelector(anchor);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.getBoundingClientRect().top + window.scrollY,
                behavior: "smooth",
            });
        }
    }

    const anchorButtons = Array.from(document.querySelectorAll(".btn-anchor"));
    anchorButtons.forEach(function (button) {
        let anchor = null;
        if (button.getAttribute("href") === null) {
            anchor = button.getAttribute("data-anchor");
        } else {
            anchor = button.getAttribute("href");
        }

        button.addEventListener("click", function () {
            gotoAnchor(anchor);
        });
    });
    // END - Scroll to anchor


    function anchorsChromeBugResolver () {
        var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        if (window.location.hash && isChrome) {
            setTimeout(function () {
                var hash = window.location.hash;
                window.location.replace(hash);
            }, 2000);
        }
    }

});


$(document).ready(function() { 
    var elem = $('#' + window.location.hash.replace('#', ''));
    if(elem) {
         $.scrollTo(elem.left, elem.top);
    }
});