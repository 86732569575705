import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {
    
    var postSidebars = document.querySelectorAll(".single-post .post-page .post-body .body-side");
  
	if (postSidebars && postSidebars.length > 0) {

        for (var i = 0; i < postSidebars.length; i++) {
            let postSidebar = postSidebars[i];

            gsap.to(postSidebar.querySelector(".first-sticky-column"), {
                scrollTrigger: {
                    trigger: postSidebar.querySelector(".newsletter-block"),
                    endTrigger: postSidebar.querySelector(".first-sticky-column"),
                    pin: true,
                    start: "top 150px",
                    end: "bottom 600px",
                    scrub: .5,
                    invalidateOnRefresh: true,
                }
            });

            gsap.to(postSidebar.querySelector(".second-sticky-column"), {
                scrollTrigger: {
                    trigger: postSidebar.querySelector(".share-card"),
                    endTrigger: postSidebar.querySelector(".second-sticky-column"),
                    pin: true,
                    start: "top 150px",
                    end: "bottom 600px",
                    scrub: .5,
                    invalidateOnRefresh: true,
                }
            });
                
        }
        
    }

});