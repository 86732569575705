let toggle = document.querySelector(".navbar-toggler") !== null ? document.querySelector(".navbar-toggler") : null;

if(toggle) {
    toggle.addEventListener("click", function (e) {
        toggleMenu();
    });
    
    function toggleMenu() {
        toggle.classList.toggle("is-active");
        document.querySelector("body").classList.toggle("no-scroll");
    }
}