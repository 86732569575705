import gsap from "gsap";

const heroFrame = document.querySelectorAll(".block-megahero .megahero-img"),
      heroOvertitle = document.querySelectorAll(".block-megahero .block-overtitle"),
      heroTitle = document.querySelectorAll(".block-megahero .block-title"),
      heroFooter = document.querySelectorAll(".block-megahero .block-heading-footer");

const initHero = () => {
    gsap.set(heroFrame, {opacity: 0});
    gsap.set(heroOvertitle, {height: 0, overflow: "hidden"});
    gsap.set(heroTitle, {height: 0, overflow: "hidden"});
    gsap.set(heroFooter, {height: 0, overflow: "hidden"});

    showHero();
};

const showHero = () => {
    gsap.timeline().to(heroOvertitle, {
        duration: 1.5,
        height: "auto", 
        ease: "expo.inOut",
        stagger: 0.025
    }).to(heroOvertitle, {
        duration: 0,
        overflow: "visible"
    }).to(heroTitle, {
        duration: .75,
        height: "auto", 
        ease: "expo.inOut",
        stagger: 0.025
    }).to(heroTitle, {
        duration: 0,
        overflow: "visible"
    }).to(heroFooter, {
        duration: .75,
        height: "auto", 
        ease: "expo.inOut",
        stagger: 0.025
    }).to(heroFooter, {
        duration: 0,
        overflow: "visible"
    }).to(heroFrame, {
        duration: 1.5,
        opacity: 1,
        ease: "expo.inOut",
        stagger: 0.025
    });
}

const animateFrames = (event) => {
    
    heroFrame.forEach((frame) => {
        let xPos = event.clientX / window.innerWidth - 0.5,
        yPos = event.clientY / window.innerHeight - 0.5;

        gsap.to(frame, {
            duration: 0.5,
            rotationY: xPos * 10,
            rotationX: yPos * 10,
            stagger: 0.055
        })
    });
};

document.addEventListener("DOMContentLoaded", () => {
    initHero();
});

document.addEventListener("mousemove", (event) => {
    animateFrames(event);
});