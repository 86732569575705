$(() => {
	if ($(".posts-page").length > 0) {
		function ajaxCall($term, paged) {
			$.ajax({
				url: WPURLS.ajaxUrl,
				data: {
					action: "filter_term",
					term: $term,
					paged: paged,
				},
				type: "post",
				success: function (result) {
					$(".js-results-wrapper").fadeOut(300, function () {
						$(".js-results").parent().html(result);
						//$(".loading-backface").removeClass("visible");
						$(".js-results-wrapper").fadeIn(300);
					});
				},
				done: function () {},
				error: function (result) {
					console.warn(result);
				},
			});
		}

		$(document).on("click", ".pagination button", function (e) {
			e.preventDefault();
			//$(".loading-backface").addClass("visible");
			ajaxCall($(this).data("term"), $(this).data("paged"));
		});

		$(document).on("click", ".js-filter-btn", function (e) {
			e.preventDefault();
			assignActive($(this));
			//$(".loading-backface").addClass("visible");
			ajaxCall($(this).data("term"), 1);
		});

		function assignActive(el) {
			$(".js-filter-btn").removeClass("active");
			el.addClass("active");
		}
	}
});
