import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import { ScrollSmoother } from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {
    

    var cardsMasonryBlocks = document.querySelectorAll(".block-cards.masonry");
  
	if (cardsMasonryBlocks && cardsMasonryBlocks.length > 0) {

        for (var i = 0; i < cardsMasonryBlocks.length; i++) {
            let cardsMasonryBlock = cardsMasonryBlocks[i];

            gsap.to(cardsMasonryBlock.querySelector(".block-body"), {
                scrollTrigger: {
                    trigger: cardsMasonryBlock.querySelector(".block-header"),
                    endTrigger: cardsMasonryBlock.querySelector(".block-end-scroll"),
                    pin: true,
                    start: "top 150px",
                    end: "bottom 500px",
                    scrub: .5,
                    invalidateOnRefresh: true,
                }
            });
                
        }
        
    }

});


$(() => {
    
    var cardsContainer = $('.block-cards .block-body, .block-minicards .block-body');

    if(cardsContainer.length > 0) {

        const navPrev = '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none"><g clip-path="url(#clip0_4492_3237)"><path d="M23.115 11.115L21 9L12 18L21 27L23.115 24.885L16.245 18L23.115 11.115Z" fill="white"/></g><defs><clipPath id="clip0_4492_3237"><rect width="36" height="36" fill="white"/></clipPath></defs></svg>';
        const navNext = '<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none"><g clip-path="url(#clip0_4492_3238)"><path d="M14.9998 9L12.8848 11.115L19.7548 18L12.8848 24.885L14.9998 27L23.9998 18L14.9998 9Z" fill="white"/></g><defs><clipPath id="clip0_4492_3238"><rect width="36" height="36" fill="white"/></clipPath></defs></svg>';
    
        var owlOptions = {
            loop: false,
            nav: true,
            dots: false,
            navText: [navPrev, navNext],
            responsive: {
                0: {
                    items: 1,
                    margin: 16,
                    autoWidth:true
                },
                768: {
                    items: 2,
                    margin: 16,
                    autoWidth:false
                }
            }
        };
    
        if ( $(window).width() < 992 ) {
            cardsContainer.addClass('owl-carousel');
            var cardsCarousel = cardsContainer.owlCarousel(owlOptions);
        } else {
            cardsContainer.removeClass('owl-carousel');
        }
    
        $(window).resize(function() {
    
            if ( $(window).width() < 992 ) {
                if ( !$('.block-cards .block-body').hasClass('owl-carousel') ) {
                    cardsContainer.addClass('owl-carousel');
                    var cardsCarousel = cardsContainer.owlCarousel(owlOptions);
                } 
            } else {
                if ( $('.block-cards .block-body').hasClass('owl-carousel') ) {
                    cardsContainer.removeClass('owl-carousel').trigger('destroy.owl.carousel');
                    cardsContainer.find('.owl-stage-outer').children(':eq(0)').unwrap();
                }
            }
    
        });

    }

});