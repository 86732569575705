import {changeStep, changeFormMessage, showFormMessage, resetFormMessage, validateForm, animatePagination} from  "./utilities";

const requestForm = document.getElementById("requestForm");

if (requestForm !== null) {

	requestForm.addEventListener('submit', function(e) {
		e.preventDefault();
	
		let currentStep = document.querySelector('.request-form .request-step[data-step="9"]') ;
	
		if (validateForm(currentStep)) {
			let form = e.target;
	
			if (form.reportValidity()) {
				
				var formMessageWrapper = form.querySelector('.form-notifications-wrapper');
				var ajaxUrl = WPURLS.ajaxUrl + '?action=' + form.getAttribute('data-action');
				var formData = new FormData(form);
				var xhr = new XMLHttpRequest();
	
				xhr.open('POST', ajaxUrl, true);
	
				xhr.onreadystatechange = function() {
	
					if (xhr.readyState === 4) {
			
						if (xhr.status === 200) {
							var data = JSON.parse(xhr.responseText);
			
							if (data.success) {
	
								const btnPrev = document.getElementById("btnPrev");
								let currentStep = document.querySelector('.request-form .request-step[data-step="9"]') ;
								let nextStep = document.querySelector('.request-form .request-step[data-step="10"]') ;
							
								changeStep(currentStep, nextStep);
								animatePagination();
	
								btnPrev.remove();
							
							} else {

								let errorString = data.message ? data.message : WPURLS.mail_message.error;
		
								if(data.invalidField) {
									errorString = " " + document.querySelector('input[name="' + data.invalidField + '"]').dataset.error;
								}
	
								changeFormMessage(formMessageWrapper, errorString, 'error');
								showFormMessage(formMessageWrapper, form.getBoundingClientRect().x);
								resetFormMessage(formMessageWrapper);

							}
	
						} 
					}
				};
	
				xhr.send(formData);
	
				e.preventDefault();
			}
	
		}
	
	}, false);

}