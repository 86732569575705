import {resetStep, changeStep, validateForm} from  "./utilities";

document.addEventListener("DOMContentLoaded", () => {
    const requestForm = document.getElementById("requestForm");
    const steps = document.querySelectorAll(".request-form .request-step");

    if (requestForm !== null) {

        for(let i = 0; i < steps.length; i++) {
            let step = steps[i];
            let btnNext = step.querySelector('.btn-next');
            let stepInputs = step.querySelectorAll('input');
    
            if(i) {
                resetStep(step);
            }
    
            if (btnNext !== null) {
        
                btnNext.addEventListener('click', (event) => {
                    let currentBtn = event.target;
                    let currentStep = document.querySelector('.request-form .request-step[data-step="' + currentBtn.dataset.current + '"]') ;
    
                    if (validateForm(currentStep)) {
    
                        if (currentBtn.dataset.next !== 'undefined') {
                            let nextStep = document.querySelector('.request-form .request-step[data-step="' + currentBtn.dataset.next + '"]') ;

                            changeStep(currentStep, nextStep);
                        }
    
                    }
                });
                
            }
    
            if (stepInputs !== null) {
    
                for (let j = 0; j < stepInputs.length; j++) {
                    let curInput = stepInputs[j];
                    
                    curInput.addEventListener("focus", () => {
                        for (let x = 0; x < stepInputs.length; x++) {
                            stepInputs[x].classList.remove('invalid');
                        }
                    });
                
                }
    
            }
    
        }

        if(btnPrev !== null) {
            btnPrev.addEventListener('click', () => {
                let currentStep = document.querySelector('.request-form .request-step[data-step="' + btnPrev.dataset.current + '"]') ;
                let prevStep = document.querySelector('.request-form .request-step[data-step="' + btnPrev.dataset.prev + '"]') ;

                changeStep(currentStep, prevStep);

            });
        }

    }
    
});