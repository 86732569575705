import gsap from "gsap";

const animatePagination = () => {
    let paginationSteps = document.querySelectorAll('.steps-pagination .step');
    let requestCompletedIcon = document.querySelector('.steps-pagination #requestCompletedIcon');
    let pageRequest = document.querySelector('.page-request');

    for (let i = 0; i < paginationSteps.length; i++) {
        
        let curPaginationStep = paginationSteps[i];

        gsap.timeline().to(paginationSteps, {
            duration: 1,
            xPercent: -200 * curPaginationStep.dataset.step,
            opacity: 0,
            ease: "expo.inOut"
        });
        
    }

    gsap.timeline().to(requestCompletedIcon, {
        duration: 1,
        opacity: 1,
        visibility: 'visible',
        left: 0,
        delay: -.75
    }).to(pageRequest, {
        duration: 1,
        backgroundColor: '#DEDEDE',
        delay: -.75
    });
}

const resetStep = (step) => {

    gsap.set(step, {
        opacity: 0,
		height: 0,
        xPercent: 100
    });

};

const changeStep = (currentStep, nextStep) => {

    const btnPrev = document.getElementById("btnPrev");
    let paginationCurrentStep = document.querySelector('.steps-pagination .step[data-step="' + currentStep.dataset.step + '"]');
    let paginationNextStep = document.querySelector('.steps-pagination .step[data-step="' + nextStep.dataset.step + '"]');

    if (parseInt(currentStep.dataset.step) > parseInt(nextStep.dataset.step)) {


        if(btnPrev.dataset.current > 1 && btnPrev.dataset.prev) {
            
            gsap.timeline().to(currentStep, {
                duration: 1,
                xPercent: 100,
                opacity: 0,
                height: 0,
                ease: "expo.inOut",
                onComplete: () => {
                    currentStep.classList.remove('active');
                    paginationNextStep.classList.add('active');
                }
            }).to(nextStep, {
                duration: 1,
                xPercent: -100 * (nextStep.dataset.step - 1),
                opacity: 1,
                height: '100%',
                ease: "expo.inOut",
                delay: -.75,
                onStart: () => {
                    nextStep.classList.add('active');
                    paginationCurrentStep.classList.remove('active');
                }
            });
    
            btnPrev.dataset.current -= 1;
            btnPrev.dataset.prev -= 1;

            if(btnPrev.dataset.current == 1 && btnPrev.dataset.prev == 0) {
                btnPrev.classList.add('disabled');
            }
        }


    } else {
        gsap.timeline().to(currentStep, {
            duration: 1,
            xPercent: -100 * currentStep.dataset.step,
            opacity: 0,
            height: 0,
            ease: "expo.inOut",
            onComplete: () => {
                currentStep.classList.remove('active');
                paginationNextStep.classList.add('active');
            }
        }).to(nextStep, {
            duration: 1,
            xPercent: -100 * currentStep.dataset.step,
            opacity: 1,
            height: '100%',
            ease: "expo.inOut",
            delay: -.75,
            onStart: () => {
                nextStep.classList.add('active');
                paginationCurrentStep.classList.remove('active');
            }
        });

        btnPrev.dataset.current = nextStep.dataset.step ;
        btnPrev.dataset.prev = currentStep.dataset.step;
        btnPrev.classList.remove('disabled');
    }

    window.scrollTo(0, 0);

}

const validateForm = (currentStepItem) => {
    let requiredInputs, valid = true, continueLoop = true;
    let currentStep = currentStepItem.dataset.step;
    var formMessageWrapper = document.querySelector('#requestForm .form-notifications-wrapper');
    let errorMessage = formMessageWrapper.dataset.defaultMessage;

    if (currentStep == 4) {

        const step4OtherInput = document.querySelector('input[name="step[4][other_details]"]');
        const step4RequiredCheckboxes = document.querySelectorAll('input[name="step[4][options]"][type="checkbox"][required]');

        step4OtherInput.dataset.required = step4OtherInput.value ? true : false;

        for(var i  = 0; i < step4RequiredCheckboxes.length; i++) {
            step4RequiredCheckboxes[i].dataset.required = step4OtherInput.dataset.required;
        }
 
    } else if (currentStep == 9) {

        const privacyInput = document.querySelector("#privacy");

        if (!privacyInput.checked) {
            valid = false;
            privacyInput.classList.add("invalid");
            errorMessage = privacyInput.dataset.error;

            changeFormMessage(formMessageWrapper, errorMessage, 'error');
            showFormMessage(formMessageWrapper, currentStepItem.getBoundingClientRect().x);
            resetFormMessage(formMessageWrapper);
        }

    }

    if (valid) {

        requiredInputs = currentStepItem.querySelectorAll('input[data-required="true"], textarea[data-required="true"]');
 
        for (let i = 0; i < requiredInputs.length && continueLoop; i++) {
            let currentInput = requiredInputs[i];
            errorMessage = currentInput.dataset.error;
            valid = true;

            if (currentInput.type == "text" || currentInput.type == "email" || currentInput.type == "textarea") {
                
                if (currentInput.value === "") {
                    valid = false;
                    continueLoop = false;
                    currentInput.classList.add("invalid");
                }

            } else if (currentInput.type == "radio" || currentInput.type == "checkbox") {

                if (!currentInput.checked) {
                    valid = false;
                } else {
                    valid = true;
                    continueLoop = false;
                }
                
            }

        }

        if (valid) {

            hideFormMessage(formMessageWrapper);
            currentStepItem.classList.add("finish");

        } else {

            let clickableInputs = currentStepItem.querySelectorAll('input[type="radio"][data-required="true"], input[type="checkbox"][data-required="true"]');
    
            for (let i = 0; i < clickableInputs.length; i++) {
                let clickableInput = clickableInputs[i];
                clickableInput.classList.add("invalid");
            }

            changeFormMessage(formMessageWrapper, errorMessage, 'error');
            showFormMessage(formMessageWrapper, currentStepItem.getBoundingClientRect().x);
            resetFormMessage(formMessageWrapper);

        }

    }

    return valid;
}

const changeFormMessage = (messageWrapper, message, type) => {

	messageWrapper.className = 'form-notifications-wrapper active ' + type;
	messageWrapper.innerHTML = message;
	messageWrapper.style.display = 'block';

}

function showFormMessage(messageWrapper, position = 0) {

    gsap.set(messageWrapper, {
        opacity: 1,
        visibility: 'visible',
        left: position
    });

}

const hideFormMessage = (messageWrapper) => {

    gsap.set(messageWrapper, {
        opacity: 0,
        visibility: 'hidden',
        left: "-100%"
    });

}

const resetFormMessage = (messageWrapper, timeout = 5000) => {

	setTimeout(function() {
		changeFormMessage(messageWrapper, '', '');
		hideFormMessage(messageWrapper);
	}, timeout);

}

export {resetStep, changeStep, changeFormMessage, showFormMessage, hideFormMessage, resetFormMessage, validateForm, animatePagination};
